
import Multiselect from "@vueform/multiselect";
import { defineComponent, onMounted, ref, reactive, watch } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { useRouter } from "vue-router";
import State from "@/components/ABilling/StateComponent.vue";
import Country from "@/components/ABilling/CounrtyComponent.vue";
import PhoneNumberComponent from "@/components/ABilling/PhoneNumberComponent.vue";
import {
  addInsuranceCompany,
  getInsuranceCompany,
  updateInsuranceCompany,
} from "@/api/code-master-insurance-company.api";
import { helpers, required } from "@vuelidate/validators";
import { getRehab } from "@/core/services/JwtService";
import useVuelidate from "@vuelidate/core";
import { getDictionary } from "@/api/dictionaries.api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import IntegerInput from "@/components/ABilling/IntegerInput.vue";

export interface Data {
  items: [];
  previousInsuranceCompany: any;
  displayInactive: boolean;
  selectedItem: any;
  isEditMode: boolean;
  orderBy: string;
  IsAdvancedOrderBy: boolean;
  isDecr: boolean;
  isDirty: boolean;
  keyword: string;
  tab: "insurance" | "billingSettings";
  dict: {
    payerTypes: { code: string; description: string }[];
    networkTypes: string[];
  };
}
export default defineComponent({
  name: "InsuranceCompanyAddPage",
  props: ["insuranceCompanyId"],
  components: {
    State,
    Country,
    Multiselect,
    PhoneNumberComponent,
    IntegerInput,
  },
  beforeRouteLeave(to, from, next) {
    if (this.data.isDirty) {
      let text = "Are you sure you want to leave without saving changes?";
      Swal.fire({
        title: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else if (result.isDismissed) {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  setup(props, ctx) {
    let organizationId = ref<string | null>("");
    const router = useRouter();

    let data = reactive<Data>({
      items: [],
      previousInsuranceCompany: {},
      displayInactive: false,
      selectedItem: undefined,
      isEditMode: true,
      keyword: "",
      orderBy: "payerId",
      IsAdvancedOrderBy: false,
      isDecr: false,
      isDirty: false,
      tab: "insurance",
      dict: {
        payerTypes: [],
        networkTypes: ["In Network", "Out Of Network"],
      },
    });

    const isPhone = helpers.regex(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im); // eslint-disable-line
    const nameReq = (data) =>
      helpers.withParams(
        { type: "nameReq", value: data },
        (value) => helpers.req(value) || !data.selectedItem.id
      );

    const InsuranceCompanyRules = {
      selectedItem: {
        initInsuranceCompanyName: {
          required: helpers.withMessage("Required", required),
        },
        name: { required: helpers.withMessage("Required", nameReq(data)) },
        officeNumber: {
          isPhone: helpers.withMessage("Phone number is invalid", isPhone),
        },
        otherNumber: {
          isPhone: helpers.withMessage("Phone number is invalid", isPhone),
        },
        fax: {
          isPhone: helpers.withMessage("Fax number is invalid", isPhone),
        },
      },
    };

    let v$ = useVuelidate(InsuranceCompanyRules, data as never);

    onMounted(async () => {
      organizationId.value = getOrganization();
      data.selectedItem = {
        payerType: null,
        network: null,
        state: null,
        country: "1",
      };

      data.dict.payerTypes = await getDictionary(17, "");
      if (props.insuranceCompanyId) {
        var company = await getInsuranceCompany(props.insuranceCompanyId);
        data.selectedItem = company;
        data.isEditMode = true;
      }

      if (data.selectedItem) {
        data.previousInsuranceCompany = JSON.parse(
          JSON.stringify(data.selectedItem)
        );
      } else {
        data.previousInsuranceCompany = {};
      }
    });

    watch(
      () => data.selectedItem,
      () => {
        ctx.emit("insuranceCompanyInfoChanged", data.selectedItem);
        var emptyValue = {};
        if (
          JSON.stringify(data.previousInsuranceCompany) !=
            JSON.stringify(emptyValue) &&
          data.selectedItem &&
          JSON.stringify(data.selectedItem) !=
            JSON.stringify(data.previousInsuranceCompany)
        ) {
          data.isDirty = true;
        }
      },
      { deep: true }
    );

    async function addItem() {
      const result = await v$.value.selectedItem.$validate();
      if (result) {
        data.selectedItem.RehabId = getRehab();
        await addInsuranceCompany(data.selectedItem);
        data.isDirty = false;
        clear();
      }
    }

    async function updateItem() {
      const result = await v$.value.selectedItem.$validate();
      if (result) {
        await updateInsuranceCompany(data.selectedItem);
        data.isDirty = false;
        clear();
      }
    }

    function edit() {
      data.isEditMode = true;
    }

    async function clear() {
      router.push({ path: "/settings/insuranceCompanyPage" });
    }

    return {
      organizationId,
      updateItem,
      addItem,
      edit,
      clear,
      v$,
      data,
    };
  },
});
